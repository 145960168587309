<template>
  <div>
    <div class="columns mb-6">
      <div class="column is-one-third">
        <div class="box box-menu is-paddingless">
          <aside class="menu-page menu">
            <ul class="menu-list">
              <li>
                <a
                  @click="setActiveMenu('languages')"
                  :class="{ 'is-active': activeMenu === 'languages' }"
                  >Languages</a
                >
              </li>
              <li>
                <a
                  @click="setActiveMenu('meetingtypes')"
                  :class="{ 'is-active': activeMenu === 'meetingtypes' }"
                  >Meeting types</a
                >
              </li>
              <li>
                <a
                  @click="setActiveMenu('mails')"
                  :class="{ 'is-active': activeMenu === 'mails' }"
                  >Confirmation mail</a
                >
              </li>
              <li>
                <a
                  @click="setActiveMenu('taxes')"
                  :class="{ 'is-active': activeMenu === 'taxes' }"
                  >VAT rates</a
                >
              </li>
              <li>
                <a
                  @click="setActiveMenu('bookingterms')"
                  :class="{ 'is-active': activeMenu === 'bookingterms' }"
                  >Booking terms</a
                >
              </li>
            </ul>
          </aside>
        </div>
      </div>
      <div class="column">
        <section class="section is-small">
          <div class="container">
            <transition name="fade" mode="out-in">
              <LocationMeetingtypes
                key="'meetingtypes'"
                v-if="activeMenu === 'meetingtypes'"
              />

              <LocationLanguages
                key="'languages'"
                v-if="activeMenu === 'languages'"
              />

              <LocationMailSettings
                key="'mails'"
                v-if="activeMenu === 'mails'"
              />

              <LocationTaxPercentages
                key="'taxes'"
                v-if="activeMenu === 'taxes'"
              />

              <div key="'bookingterms'" v-if="activeMenu === 'bookingterms'">
                <LocationBookingTermStatus />
              </div>
            </transition>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    LocationLanguages: () =>
      import('@/components/Locations/Settings/LocationLanguages'),
    LocationMailSettings: () =>
      import('@/components/Locations/Settings/LocationMailSettings'),
    LocationMeetingtypes: () =>
      import('@/components/Locations/Settings/LocationMeetingtypes'),
    LocationTaxPercentages: () =>
      import('@/components/Locations/Settings/LocationTaxPercentages'),
    LocationBookingTermStatus: () =>
      import('@/components/Locations/Settings/LocationBookingTermStatus'),
  },

  props: {},

  data() {
    return {
      activeMenu: 'languages',
    }
  },

  computed: {
    ...mapState('locationStore', ['location']),
  },

  created() {},

  methods: {
    setActiveMenu(item) {
      this.activeMenu = item
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
